// src/services/axiosInstance.ts
import axios from 'axios';

// Get the context in your component
// Create an axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
  withCredentials: true,
  validateStatus:  (status) => {
    return status >= 200 && status < 500; // Accept all 2xx and 3xx status codes
  }
});

// Add a request interceptor
instance.interceptors.request.use((config) => {
  // Dispatch a custom event with detail set to true when a request starts
  window.dispatchEvent(new CustomEvent('loading', { detail: true }));
  return config;
});

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    window.dispatchEvent(new CustomEvent('loading', {detail: false}));
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with the response data
    return response;
  }, 
  error => {
    window.dispatchEvent(new CustomEvent('loading', {detail: false}));
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
        window.dispatchEvent(new Event('logout'));
    }
    return error;
  }
);

export default instance;