import React from "react";

// Footer.js
function Footer() {
  return (
    <footer>
      <p>© 2022 My App</p>
    </footer>
  );
}

export default Footer;
