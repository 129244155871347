import React, { useState } from "react";

function TooltipIcon({ tooltip, className, children }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="absolute z-10 w-32 px-2 py-1 text-sm text-white bg-black rounded">
          {tooltip}
        </div>
      )}
    </div>
  );
}

export default TooltipIcon;
