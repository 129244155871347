import React from "react";

type MultiLineInputProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
};

function MultiLineInput({
  value,
  setValue,
  placeholder,
  disabled,
}: MultiLineInputProps) {
  return (
    <div className={`w-full ${disabled ? "bg-gray-200 text-gray-400" : ""}`}>
      <textarea
        className="relative w-full cursor-default rounded-lg bg-white border border-gray-200 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
}

export default MultiLineInput;
