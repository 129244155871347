import React, { createContext, useState, useEffect } from "react";
import axios from "../services/axiosInstance.ts";

interface User {
  id: string;
  username: string;
  permissions: string[];
  role: string;
}

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  user?: User;
  logout: () => void;
}

// Create a context
export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  setIsLoggedIn: (value: boolean) => {},
  logout: () => {},
  user: undefined,
});

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (isLoggedIn) return;
    const getUser = async () => {
      const response = await axios.get("/auth/user");
      if (response?.data?.error) {
        return { isLoggedIn: false, user: undefined };
      }
      return response.data;
    };
    getUser().then(
      ({ isLoggedIn: authed, user }: { isLoggedIn: boolean; user: User }) => {
        setUser(user);
        setIsLoggedIn(authed);
      }
    );
  }, [isLoggedIn]);

  useEffect(() => {
    const logoutListener = async () => {
      try {
        await axios.get("/auth/logout");
      } finally {
        setIsLoggedIn(false);
        setUser(undefined);
      }
    };

    window.addEventListener("logout", logoutListener);

    return () => {
      window.removeEventListener("logout", logoutListener);
    };
  }, []);

  const logout = () => {
    setIsLoggedIn(false);
    setUser(undefined);
    // You can also clear any stored user data here
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};
