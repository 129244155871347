import EditRequirementsPage from "./pages/edit-requirements.tsx";
import CopyRequirementsPage from "./pages/copy-requirements.tsx";
import ErrorLogPage from "./components/ErrorLog/ErrorLog.tsx";
import AuthPage from "./pages/auth.tsx";

interface Route {
    path: string;
    element:()=>React.JSX.Element;
    label: string;
    onClick?: () => void;
}

const routes : Route[] = [
    {
        path: "/edit-requirements",
        element: EditRequirementsPage,
        label: "Edit Requirements",
    },
    {
        path: "/error-log",
        element: ErrorLogPage,
        label: "Report Errors",
    },
    {
        path: "/copy-requirements",
        element: CopyRequirementsPage,
        label: "Copy Requirements",
    },
    {
        path: "/logout",
        element: AuthPage,
        label: "Logout",
        onClick: () => {
            window.dispatchEvent(new Event("logout"));
        }
    },
];

export default routes;