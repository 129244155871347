import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import AppButton from "../Form/Button.tsx";
import SingleDropdown from "../Form/SingleDropdown.tsx";
import MultiLineInput from "../Form/MultiLineTextEdit.tsx";
import TextInput from "../Form/TextInput.tsx";
import { type Option, Requirement } from "../../types";

interface AddRequirementModalProps {
  sources: Option[];
  onRequirementAdd: (requirement: Requirement) => void;
}

function AddRequirementModal({
  sources,
  onRequirementAdd,
}: AddRequirementModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [source, setSource] = useState<Option>({ label: "", value: "" });
  const [requirement, setRequirement] = useState("");
  const [page, setPage] = useState("");

  const disabled = !source.value || !requirement || page === "" || page == null;

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSource({ label: "", value: "" });
      setRequirement("");
      setPage("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (sources && sources.length === 1) setSource(sources[0]);
  }, [sources]);

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    // Handle form submission here
    console.log({ source, requirement, page });
    onRequirementAdd({
      source: source.value,
      requirement,
      page: parseInt(page),
    });
    closeModal();
  };

  return (
    <>
      <AppButton
        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-none rounded-tr-md rounded-br-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={() => openModal()}
      >
        Add Requirement
      </AppButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add Requirement
              </Dialog.Title>

              <form className="mt-2 flex flex-col space-y-4">
                <SingleDropdown
                  value={source}
                  setValue={setSource}
                  options={sources}
                  placeholder="Select Source"
                />
                <TextInput
                  value={page}
                  type="number"
                  setValue={setPage}
                  placeholder="Page Number"
                />
                <MultiLineInput
                  value={requirement}
                  setValue={setRequirement}
                  placeholder="Enter requirement here"
                />

                <div className="mt-4">
                  <AppButton
                    disabled={disabled}
                    className={`${
                      disabled ? "opacity-60 cursor-not-allowed" : ""
                    } bg-blue-500 text-blue-500 hover:text-blue-200 rounded-md`}
                    onClick={(e: Event) => handleSubmit(e)}
                  >
                    Submit
                  </AppButton>
                </div>
              </form>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AddRequirementModal;
