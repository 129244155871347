// Login.tsx
import React, { useState, useContext } from "react";
import SingleLineInput from "../Form/TextInput.tsx";
import AppButton from "../Form/Button.tsx";
import axios from "../../services/axiosInstance.ts";
import { AuthContext } from "../../contexts/AuthProvider.tsx";

const Login = ({ setIsLoggedIn: Function }) => {
  const { setIsLoggedIn } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    console.log("handle submit");
    event.preventDefault();
    // Handle login logic here
    const response = await axios.post(`/auth/login`, {
      username,
      password,
    });

    console.log(response.data);
    if (response.status === 200 && response.data.success) {
      setIsLoggedIn(true);
    }
  };

  return (
    <form className="flex flex-col space-y-2" onSubmit={handleSubmit}>
      <SingleLineInput
        value={username}
        setValue={setUsername}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
        placeholder="Email"
      />
      <SingleLineInput
        value={password}
        setValue={setPassword}
        type="password"
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
        placeholder="Password"
      />
      <div className="flex justify-center items-center">
        <AppButton onClick={(e) => handleSubmit(e)}>Submit</AppButton>
      </div>
    </form>
  );
};

export default Login;
