// Register.tsx
import React, { useState } from "react";
import SingleLineInput from "../Form/TextInput.tsx";
import AppButton from "../Form/Button.tsx";

const Register = ({ setIsLoggedIn: Function }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle registration logic here
  };

  return (
    <form className="flex flex-col space-y-2" onSubmit={handleSubmit}>
      <SingleLineInput value={email} setValue={setEmail} placeholder="Email" />
      <SingleLineInput
        value={password}
        setValue={setPassword}
        placeholder="Password"
      />
      <div className="flex justify-center items-center">
        <AppButton onClick={() => handleSubmit}>Submit</AppButton>
      </div>{" "}
    </form>
  );
};

export default Register;
