import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import AppButton from "../Form/Button.tsx";
import MultiLineInput from "../Form/MultiLineTextEdit.tsx";
import { FaTrash, FaExpandArrowsAlt, FaExpand } from "react-icons/fa";
import axios from "../../services/axiosInstance.ts";
import ConfirmationModal from "../Utility/ConfirmationModal.tsx";
import AddRequirementModal from "./AddRequirementModal.tsx";
import { type Option, Requirement } from "../../types";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

interface RequirementsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  country: string;
  province: string;
  city: string;
  zone: string;
  keyword: string;
  topic: string;
}

function RequirementsModal({
  isOpen,
  setIsOpen,
  country,
  province,
  city,
  zone,
  keyword,
  topic,
}: RequirementsModalProps) {
  const [requirements, setRequirements] = useState<Requirement[]>([]);
  const [uniqueSources, setUniqueSources] = useState<Option[]>([]);
  const [count, setCount] = useState<number>(0);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState<number | undefined>();

  const deleteRequirement = (index: number) => {
    setRequirements(requirements.filter((_, i) => i !== index));
  };

  const confirmDelete = () => {
    if (toBeDeleted === undefined) return;
    deleteRequirement(toBeDeleted);
    setIsConfirmationModalOpen(false);
  };

  const handleDelete = (index: number) => {
    setToBeDeleted(index);
    setIsConfirmationModalOpen(true);
  };

  const editRequirement = (index: number, newRequirement: string) => {
    setRequirements(
      requirements.map((req, i) => {
        if (i === index) {
          return { ...req, requirement: newRequirement };
        }
        return req;
      })
    );
  };

  const addRequirement = (requirement: Requirement) => {
    setRequirements([...requirements, requirement]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(requirements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRequirements(items); // Assuming you have a state setter function like this
  };

  useEffect(() => {
    if (!isOpen) return;
    const getRequirements = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${country}/${province}/${city}/${zone}/${topic}/${keyword}/requirements`
      );
      console.log(response);
      console.log(response.data);
      return response.data.requirements;
    };
    getRequirements().then((requirements: Requirement[]) => {
      setRequirements(requirements);
      setUniqueSources(
        [...new Set(requirements.map((r) => r.source))].map((s) => ({
          label: s.split("/")[s.split("/").length - 1],
          value: s,
        }))
      );
    });
  }, [country, province, city, zone, topic, keyword, isOpen, count]);

  const saveRequirements = async () => {
    console.log("Saving requirements", requirements);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${country}/${province}/${city}/${zone}/${topic}/${keyword}/requirements`,
      { requirements }
    );
    console.log(response.data);
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto w-full h-full bg-black bg-opacity-50"
          onClose={() => setIsOpen(false)}
        >
          <div className="max-h-screen max-w-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block w-full max-w-[100%] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Requirements:{" "}
                {`${country} ${province}, ${city}, ${zone}, ${topic}, ${keyword}`}
              </Dialog.Title>
              <div className="mt-2 w-[100%]">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="requirements">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {requirements.map((requirement, index) => (
                          <Draggable
                            key={`requirement-${index}`}
                            draggableId={`requirement-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex flex-row space-x-2 items-center"
                              >
                                <div {...provided.dragHandleProps}>
                                  <FaExpand />{" "}
                                  {/* Replace DragIcon with your actual drag icon component */}
                                </div>
                                <MultiLineInput
                                  value={requirement.requirement}
                                  setValue={(value: string) => {
                                    editRequirement(index, value);
                                  }}
                                  disabled={false}
                                  placeholder="Enter requirements here"
                                />
                                <AppButton
                                  className="bg-red-500 text-red-500 hover:text-red-200"
                                  onClick={() => {
                                    handleDelete(index);
                                  }}
                                >
                                  <FaTrash />
                                </AppButton>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="flex justify-between mt-4">
                <div>
                  <AppButton
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-none rounded-tl-md rounded-bl-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => saveRequirements()}
                  >
                    Save
                  </AppButton>
                  <AddRequirementModal
                    onRequirementAdd={addRequirement}
                    sources={uniqueSources}
                  />
                </div>
                <div>
                  <AppButton
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-none rounded-tl-md rounded-bl-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => setCount(count + 1)}
                  >
                    Reset
                  </AppButton>
                  <AppButton
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-none rounded-tr-md rounded-br-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </AppButton>
                </div>
              </div>
              <ConfirmationModal
                text="Are you sure you want to delete this?"
                isOpen={isConfirmationModalOpen}
                setIsOpen={setIsConfirmationModalOpen}
                onConfirm={confirmDelete}
              />
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default RequirementsModal;
