// Sidebar.js
import React from "react";
import { Link } from "react-router-dom";
import routes from "../../routes.ts";

function Sidebar() {
  return (
    <aside className="h-screen w-64 bg-gray-800 text-white p-6">
      <ul className="space-y-4 text-left">
        {routes.map((route) => (
          <li key={route.path}>
            <Link
              onClick={route.onClick ?? (() => {})}
              to={route.path}
              className="hover:text-blue-500"
            >
              {route.label}
            </Link>
            {/* <Link to="/edit-requirements" className="hover:text-blue-500">
            Edit Requirements
          </Link> */}
          </li>
        ))}
        {/* <li><Link to="/admin-dashboard" className="hover:text-blue-500">Admin Dashboard</Link></li> */}
      </ul>
    </aside>
  );
}

export default Sidebar;
