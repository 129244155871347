import React, { useState, useContext, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import Header from './components/Layout/Header.tsx';
import Footer from './components/Layout/Footer.tsx';
import Sidebar from './components/Layout/Sidebar.tsx';
import AppRoutes from './AppRoutes.tsx';
import AuthPage from './pages/auth.tsx';
import Spinner from './components/Layout/Spinner.tsx';
import { AuthContext } from './contexts/AuthProvider.tsx';
import './App.css';

function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const startLoading = () => setIsLoading(true);
    const stopLoading = () => setIsLoading(false);

    window.addEventListener('loading', (event) => {
      const isLoading = event.detail;
      if (isLoading) {
        startLoading();
      } else {
        stopLoading();
      }
    });

    return () => {
      window.removeEventListener('loading', startLoading);
      window.removeEventListener('loading', stopLoading);
    };
  }, []);

  const routes = useRoutes([
    { path: '/auth', element: <AuthPage /> },
    { path: '*', element: isLoggedIn ? <AppRoutes /> : <AuthPage /> },
  ]);

  if (!isLoggedIn) {
    return (<div className=' w-[100vw] h-[80vh] flex justify-center items-center'>{routes}</div>);
  }

  return (
    <div className="App flex flex-col min-h-screen">
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <div className="content flex flex-grow">
        <div className={`sidebar transition-all duration-500 ${isSidebarOpen ? 'block' : 'hidden'} w-64`}>
          <Sidebar />
        </div>

        <main className="flex-grow">
          {routes}
        </main>
      </div>
      <Footer className="py-4 bg-gray-800 text-white text-center" />
      <Spinner isLoading={isLoading} />
    </div>
  );
}

export default App;