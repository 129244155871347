import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import axios from "../../services/axiosInstance.ts";
import { FaTimesCircle as ClearErrorIcon } from "react-icons/fa";
import Tooltip from "../Form/Tooltip.tsx";
import ConfirmationModal from "../Utility/ConfirmationModal.tsx";

interface ReportError {
  message: string;
  code: number;
}

interface ReportDTO {
  _id: string;
  address: string;
  email: string;
  date: string;
  errors: ReportError[];
  paid: boolean;
  structured: boolean;
  generated: boolean;
  sent: boolean;
}

interface ReportItemProps {
  report: ReportDTO;
  onClearError?: (errorId: number) => void;
  onGenerateReport?: () => void;
  onSendReport?: () => void;
  onStructureReport?: () => void;
}

function ReportItem({
  report,
  onClearError,
  onGenerateReport,
  onSendReport,
  onStructureReport,
}: ReportItemProps) {
  let statusColor = "";
  if (report.paid) statusColor = "bg-green-500";
  else if (report.structured) statusColor = "bg-blue-500";
  else if (report.generated) statusColor = "bg-yellow-500";
  else if (report.sent) statusColor = "bg-red-500";

  const formatDateTime = (date: string) => {
    // change to local timezone: YYYY-MM-DD HH:MM
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="text-left p-4 border border-gray-200 space-x-2 w-full sm:max-w-xl">
      <div className="flex flex-row justify-between">
        <div>
          <div className="text-lg font-bold">{report._id}</div>
          <div className="text-lg font-bold">{report.address}</div>
          <div>{formatDateTime(report.date)}</div>
          <p>{report.email}</p>
        </div>
        <div className="flex flex-row justify-start items-center space-x-2">
          <span className={`px-2 py-1 text-white rounded ${statusColor}`}>
            {report.paid
              ? "Paid"
              : report.structured
              ? "Structured"
              : report.generated
              ? "Generated"
              : "Sent"}
          </span>
        </div>
        {/* ... */}
      </div>
      <div className="w-[100%] mt-4 grid grid-cols-[auto,auto,auto] gap-4 mt-4">
        <div className="font-semibold">Code</div>
        <div className="font-semibold">Message</div>
        <div className="font-semibold">Action</div>
        {report.errors.map((error: ReportError, idx: number) => (
          <Fragment key={idx}>
            <div className="flex justify-start items-center">{error.code}</div>
            <div className="flex justify-start items-center">
              {error.message}
            </div>
            <Tooltip className="p-2" tooltip="Clear Error">
              <button
                onClick={() => onClearError && onClearError(error.code)}
                className="text-center px-2 py-2 bg-red-500 text-white rounded"
              >
                <ClearErrorIcon />
              </button>
            </Tooltip>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function ErrorLog() {
  const [reports, setReports] = useState<ReportDTO[]>([]);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const [confirmationFocus, setConfirmationFocus] = useState<{
    reportId: string;
    errorCode: number;
  } | null>(null);

  const getStatus = (
    paid: boolean,
    structured: boolean,
    generated: boolean,
    sent: boolean
  ) => {
    if (sent) return "sent";
    if (generated) return "generated";
    if (structured) return "structured";
    if (paid) return "paid";
    return "error";
  };

  const clearError = async (reportId: string, errorCode: number) => {
    try {
      await axios.patch(`/report/errors/${reportId}/${errorCode}`);
      setReports((prevReports) =>
        prevReports
          .map((r) => {
            if (r._id === reportId) {
              return {
                ...r,
                errors: r.errors.filter((e) => e.code !== errorCode),
              };
            }
            return r;
          })
          .filter((r) => r.errors.length > 0)
      );
    } catch (error) {
      console.error("Error clearing error", error);
    }
  };

  useEffect(() => {
    const fetchReports = async () => {
      const response = await axios.get("/report/errors");
      console.log(response?.data);
      return response?.data?.reports || [];
    };

    fetchReports().then((data) => {
      setReports(
        data.map((r: any) => ({
          ...r,
          status: getStatus(r.paid, r.structured, r.generated, r.sent),
          date: r.createdAt,
          address: r.property.address,
        }))
      );
    });
  }, [setReports]);

  return (
    <>
      <div className="m-4 flex flex-col items-center space-y-4">
        {reports.length > 0 ? (
          reports.map((report: ReportDTO) => (
            <ReportItem
              onClearError={(errorId: number) => {
                setConfirmationFocus({
                  reportId: report._id,
                  errorCode: errorId,
                });
                setConfirmationModalVisible(true);
              }}
              key={report._id}
              report={report}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-screen">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="text-center text-2xl font-bold text-green-500">
                No errors found
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white">
        <ConfirmationModal
          text="Are you sure you want to clear this error?"
          onConfirm={() => {
            if (confirmationFocus) {
              clearError(
                confirmationFocus.reportId,
                confirmationFocus.errorCode
              );
            }
            setConfirmationModalVisible(false);
          }}
          isOpen={confirmationModalVisible}
          setIsOpen={setConfirmationModalVisible}
        />
      </div>
    </>
  );
}

export default ErrorLog;
