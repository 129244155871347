import React from "react";

const AppButton = ({ children, onClick, className = "", ...props }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`py-2 px-4 bg-blue-500 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default AppButton;
