import { Listbox } from "@headlessui/react";
import React, { useState, Fragment } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import { type Option } from "../../types";

interface SingleDropdownProps {
  options: Option[];
  value: Option | null;
  setValue: Function;
  placeholder?: string;
  disabled?: boolean;
}

function SingleDropdown({
  options,
  value,
  setValue,
  placeholder,
  disabled,
}: SingleDropdownProps) {
  return (
    <div className={`w-full ${disabled ? "bg-gray-200 text-gray-400" : ""}`}>
      <Listbox disabled={disabled} value={value} onChange={(e) => setValue(e)}>
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white border border-gray-200 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{value?.label || placeholder}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="shadow-md">
          {options.map((o, i) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */
            <Listbox.Option
              key={`${o.value}-${i}`}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                }`
              }
              value={o}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`text-left block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {o.label}
                  </span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                      <FaCheck className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}

export default SingleDropdown;
