import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./routes.ts";

function AppRoutes() {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} Component={route.element} />
      ))}
    </Routes>
  );
}

export default AppRoutes;
