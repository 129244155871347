import React, { useState, useEffect } from "react";
// Your countries array
import axios from "../../services/axiosInstance.ts";
import SingleDropdown from "../Form/SingleDropdown.tsx";
import AppButton from "../Form/Button.tsx";
import DateInput from "../Form/DateInput.tsx";
import { type Option } from "../../types.ts";
import RequirementsModal from "./RequirementsModal.tsx";

interface Topic {
  keywords: string[];
  name: string;
  lastUpdated: string;
  createdAt: string;
  _id: string;
}

function EditRequirements() {
  const [topicKeywordMap, setTopicKeywordMap] = useState<Topic[]>([]);

  const [keywords, setKeywords] = useState<Option[]>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<Option | null>(null);

  const [topics, setTopics] = useState<Option[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<Option | null>(null);

  const [countries, setCountries] = useState<Option[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Option | null>(null);

  const [provinces, setProvinces] = useState<Option[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Option | null>(null);

  const [cities, setCities] = useState<Option[]>([]);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);

  const [zones, setZones] = useState<Option[]>([]);
  const [selectedZone, setSelectedZone] = useState<Option | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (
      selectedTopic == null ||
      selectedZone == null ||
      selectedCountry == null ||
      selectedProvince == null ||
      selectedCity == null
    ) {
      return;
    }

    const getKeywords = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${selectedCountry.value}/${selectedProvince.value}/${selectedCity.value}/${selectedZone.value}/${selectedTopic.value}/keywords`
      );
      console.log(response.data);
      return response.data.keywords;
    };

    getKeywords().then((keywords: string[]) => {
      console.log(keywords);
      const mapped = keywords.map((k: string) => ({ label: k, value: k }));
      setKeywords(mapped);
      if (keywords.length === 1) {
        setSelectedKeyword(mapped[0]);
      }
    });
  }, [
    selectedTopic,
    selectedZone,
    topicKeywordMap,
    selectedCountry,
    selectedProvince,
    selectedCity,
  ]);

  useEffect(() => {
    const getTopics = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/topics`
      );
      return response.data.topics;
    };
    getTopics().then((topics: Topic[]) => {
      console.log(topics);
      const mapped = topics.map((t: Topic) => ({
        label: t.name,
        value: t.name,
      }));
      setTopics(mapped);
      setTopicKeywordMap(topics);
      if (topics.length > 0) {
        setSelectedTopic(mapped[0]);
      }
    });
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`
      );
      return response.data.countries;
    };
    getCountries().then((countries) => {
      console.log(countries);
      setCountries(countries.map((c: string) => ({ label: c, value: c })));
    });
  }, []);

  useEffect(() => {
    setSelectedProvince(null);
    if (!selectedCountry) return;

    const getProvinces = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${selectedCountry.value}/provinces`
      );
      return response.data.provinces;
    };
    getProvinces().then((provinces) => {
      setProvinces(provinces.map((p: string) => ({ label: p, value: p })));
    });
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(null);
    if (!selectedProvince || !selectedCountry) return;

    const getCities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${selectedCountry.value}/${selectedProvince.value}/cities`
      );
      return response.data.cities;
    };
    getCities().then((cities) => {
      setCities(cities.map((p: string) => ({ label: p, value: p })));
    });
  }, [selectedProvince, selectedCountry]);

  useEffect(() => {
    setSelectedZone(null);
    if (
      !selectedCity ||
      !selectedCountry ||
      !selectedProvince ||
      !selectedTopic
    )
      return;

    const getZones = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${selectedCountry.value}/${selectedProvince.value}/${selectedCity.value}/zones`,
        { params: { topic: selectedTopic.value } }
      );
      return response.data.zones;
    };
    getZones().then((zones) => {
      const transformed = zones
        .map((p: string) => ({ label: p, value: p }))
        .sort((a: Option, b: Option) => a.label.localeCompare(b.label));
      setZones(transformed);
    });
  }, [selectedProvince, selectedCountry, selectedCity, selectedTopic]);

  const handleRequirementChange = (index: number, value: string) => {
    // const newRequirements = [...requirements];
    // newRequirements[index] = value;
    // setRequirements(newRequirements);
  };

  const handleRequirementDelete = (index: number) => {
    // const newRequirements = [...requirements];
    // newRequirements.splice(index, 1);
    // setRequirements(newRequirements);
  };

  const handleRequirementEdit = (index: number) => {
    // const newRequirements = [...requirements];
    // newRequirements[index] = "Edited";
    // setRequirements(newRequirements);
  };

  return (
    <div className="max-w-sm mx-auto p-6 mt-6 bg-white shadow rounded">
      <form className="space-y-6">
        <div className="flex flex-col space-y-2">
          <div className="text-bold text-xl">
            Select which requirements to edit
          </div>
          <DateInput onDateChange={(date: Date) => console.log(date)} />
          <SingleDropdown
            options={topics}
            value={selectedTopic}
            setValue={setSelectedTopic}
            placeholder="Select Topic"
          />
          <SingleDropdown
            options={countries}
            value={selectedCountry}
            setValue={setSelectedCountry}
            placeholder="Select Country"
          />
          <SingleDropdown
            options={provinces}
            value={selectedProvince}
            disabled={provinces.length === 0}
            setValue={setSelectedProvince}
            placeholder="Select Province"
          />
          <SingleDropdown
            options={cities}
            value={selectedCity}
            disabled={cities.length === 0}
            setValue={setSelectedCity}
            placeholder="Select City"
          />
          <SingleDropdown
            options={zones}
            value={selectedZone}
            disabled={zones.length === 0 || selectedTopic === null}
            setValue={setSelectedZone}
            placeholder="Select Zone"
          />
          <SingleDropdown
            options={keywords}
            value={selectedKeyword}
            disabled={
              keywords.length === 0 ||
              selectedZone == null ||
              selectedTopic === null
            }
            setValue={setSelectedKeyword}
            placeholder="Select Keyword"
          />

          <AppButton
            disabled={
              selectedCountry == null ||
              selectedCity == null ||
              selectedProvince == null ||
              selectedZone == null ||
              selectedKeyword == null ||
              selectedTopic == null
            }
            onClick={() => setModalOpen(true)}
          >
            Submit
          </AppButton>
        </div>
      </form>
      {selectedCountry != null &&
      selectedCity != null &&
      selectedProvince != null &&
      selectedZone != null &&
      selectedKeyword != null &&
      selectedTopic != null ? (
        <RequirementsModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          country={selectedCountry.value}
          province={selectedProvince.value}
          city={selectedCity.value}
          zone={selectedZone.value}
          keyword={selectedKeyword.value}
          topic={selectedTopic.value}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default EditRequirements;
