import Login from "../components/Auth/Login.tsx";
import Register from "../components/Auth/Register.tsx";
import ForgotPassword from "../components/Auth/ForgotPassword.tsx";
import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthProvider.tsx";
import AppButton from "../components/Form/Button.tsx";

const Auth = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  const [mode, setMode] = useState<"login" | "register" | "forgotPassword">(
    "login"
  );

  const comp = () => {
    if (mode === "login") {
      return <Login setIsLoggedIn={setIsLoggedIn} />;
    } else if (mode === "register") {
      return <Register setIsLoggedIn={setIsLoggedIn} />;
    } else {
      return <ForgotPassword />;
    }
  };

  return (
    <div className="max-w-sm mx-auto p-6 mt-6 bg-white shadow rounded">
      <div className="flex flex-col space-y-2">
        {isLoggedIn ? (
          <div className="text-4xl text-bold text-center">Welcome</div>
        ) : (
          <div>
            <div className="text-2xl text-bold text-center mb-2">
              {mode === "login"
                ? "Login"
                : mode === "register"
                ? "Register"
                : "Forgot Password"}
            </div>
            <div className="mb-4">{comp()}</div>
            <div className="flex flex-col space-y-2">
              <AppButton onClick={() => setMode("login")}>Login</AppButton>
              <AppButton onClick={() => setMode("register")}>
                Register
              </AppButton>
              <AppButton onClick={() => setMode("forgotPassword")}>
                Forgot Password
              </AppButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;
