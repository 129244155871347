// Header.tsx
import React from "react";
import { FaBars } from "react-icons/fa";

interface HeaderProps {
  setIsSidebarOpen: Function;
  isSidebarOpen: boolean;
}

function Header(props: HeaderProps) {
  return (
    <header className="bg-blue-500 text-white p-6 flex justify-between items-center">
      <button onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
        <FaBars size={30} color="white" />
      </button>
      <h1 className="text-lg">Laneway Admin</h1>
      <nav>
        <ul className="flex space-x-4">
          <li>
            <a href="/home" className="hover:text-gray-300">
              Home
            </a>
          </li>
          <li>
            <a href="/about" className="hover:text-gray-300">
              About
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:text-gray-300">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
