// ForgotPassword.tsx
import React, { useState } from "react";
import SingleLineInput from "../Form/TextInput.tsx";
import AppButton from "../Form/Button.tsx";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle forgot password logic here
  };

  return (
    <form className="flex flex-col space-y-2" onSubmit={handleSubmit}>
      <SingleLineInput value={email} setValue={setEmail} placeholder="Email" />
      <div className="flex justify-center items-center">
        <AppButton onClick={() => handleSubmit}>Submit</AppButton>
      </div>{" "}
    </form>
  );
};

export default ForgotPassword;
