import React, { useState } from "react";

const DateInput = ({ onDateChange }) => {
  const [date, setDate] = useState("");

  const handleDateChange = (e) => {
    setDate(e.target.value);

    // Create a new JavaScript Date object based on the input value
    const selectedDate = new Date(e.target.value);

    // Pass the selected date to the parent component
    onDateChange(selectedDate);
  };

  return (
    <input
      type="date"
      value={date}
      onChange={handleDateChange}
      className="py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
    />
  );
};

export default DateInput;
